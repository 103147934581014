.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

.skeleton_item_title {
  animation: skeleton-loading 1s linear infinite alternate;
  width: 100%;
  height: 1.6rem;
  margin-bottom: 0.25rem;
  border-radius: 0.125rem;
}

.skeleton_title {
  animation: skeleton-loading 1s linear infinite alternate;
  width: 300px;
  height: 2rem;
}

.skeleton_text {
  animation: skeleton-loading 1s linear infinite alternate;
  width: 100%;
  height: 1rem;
  margin-bottom: 0.25rem;
  border-radius: 0.125rem;
}

.skeleton_text:last-child {
  margin-bottom: 0;
  width: 80%;
}

.skeleton_tablerow {
  animation: skeleton-loading 1s linear infinite alternate;
  width: 100%;
  height: 4rem;
  margin-bottom: 0.25rem;
  border-radius: 0.125rem;
}

@keyframes skeleton-loading {
  0% {
    background-color: ☐hsl(0, 0%, 95%);
  }
  100% {
    background-color: hsl(0, 0%, 80%);
  }
}
