.loader {
    stroke: #bd10e0;
    stroke-width: 50px;
    stroke-linecap: round;
    animation: dashArray 4s ease-in-out infinite, dashOffset 2s linear infinite;
}

@keyframes dashArray {
    0% {
        stroke-dasharray: 0 1 359 0;
    }

    50% {
        stroke-dasharray: 0 359 1 0;
    }

    100% {
        stroke-dasharray: 359 1 0 0;
    }
}

@keyframes dashOffset {
    0% {
        stroke-dashoffset: 365;
    }

    100% {
        stroke-dashoffset: 5;
    }
}
